var $ = require('jquery');
var dt = require('datatables.net-dt');
const { forEach } = require('jszip');
window.JSZip = require('jszip');
require('pdfmake');
require('datatables.net-buttons-dt');
require('datatables.net-buttons/js/buttons.colVis.js')();
require('datatables.net-buttons/js/dataTables.buttons.min')();
require('datatables.net-buttons/js/buttons.flash.min')();
require('datatables.net-buttons/js/buttons.html5.js')();
require('datatables.net-rowgroup-dt');
const moment = require('moment');
const { ajax } = require('jquery');

$(document).on('turbolinks:load', () => {
  var img64 = window.enterpriseLogo;
  var tableReports = null;
  var total_balance = 0;
  var type_r_class = '';
  var buttons_or_sum = 0;
  var ivaColumn = 0;
  var numFile = 1;  
  var isOtherPage = true;
  var titleTable = "";
  var finalDateTable = "N/A";
  var initialDateTable = "N/A";
  var currentdate = new Date();
  var sum_after = 0;
  var typeColumn = 1;
  var columnsVisible = range(1,12);
  var datetime = currentdate.getDate() + "/"
              + (currentdate.getMonth()+1)  + "/"
              + currentdate.getFullYear();
  var AUTH_TOKEN = $("meta[name=csrf-token]").attr("content");
  if ($('#type-record').length) {
    var type_record = $('#type-record').text();
  }

  if ( $("#incomes-records").length ) { 
    titleTable = "Registro de ingresos";
    buttons_or_sum = {
      data: "data_buttons",
      render:function(data, type, row)
      {
        rolUser = $("#rol-user").val();
        if (rolUser == "project_manager" || rolUser == "seller" || rolUser == "administrator_only_read" || rolUser == "administrator_only_read" || rolUser == "archost_admin_only_read" || rolUser == "archost_admin_only_read_hours") {
          return "";
        } else {
          file = '';

          if (data[1] == true) {
            file = 'text-blue-600';
          };

          return "<div class='flex flex-row'><td><a target='_blank' href='/records/edit?record_id="+data[0]+"'>"+ 
          "<svg class='w-6 h-6 cursor-pointer m-auto' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'>"+
            "<path stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z'></path>"+
          "</svg>"+
          "</a>" +
          "<svg class='" + file + " w-6 h-6 cursor-pointer mx-auto hover:bg-blue-200 icon-file' fill='none' onclick='openModalFiles(this)' stroke='currentColor' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>" +
            "<path stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13'></path>" +
          "</svg>" +
          "<svg onclick='deleteRecord(this)' class='w-6 h-6 cursor-pointer text-red-600 m-auto' xmlns='http://www.w3.org/2000/svg' class='h-6 w-6' fill='none' viewBox='0 0 24 24' stroke='currentColor'>"+
            "<path stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16' />"+
          "</svg></td></div>";
        }
      }
    }
    type_r_class = { className: "hidden", "targets": 2}

  }

  if ( $("#expenses-records").length ) { 
    titleTable = "Registro de egresos";
    buttons_or_sum = {
      data: "data_buttons",
      render:function(data, type, row)
      {
        rolUser = $("#rol-user").val();
        if (rolUser == "project_manager" || rolUser == "seller" || rolUser == "administrator_only_read" || rolUser == "archost_admin_only_read" || rolUser == "archost_admin_only_read_hours") {
          return "";
        } else {
          file = '';

          if (data[1] == true) {
            file = 'text-blue-600';
          };

          return "<div class='flex flex-row'><td><a target='_blank' href='/records/edit?record_id="+data[0]+"'>"+ 
          "<svg class='w-6 h-6 cursor-pointer m-auto' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'>"+
            "<path stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z'></path>"+
          "</svg>"+
          "</a>" +
          "<svg class='" + file + " w-6 h-6 cursor-pointer mx-auto hover:bg-blue-200 icon-file' fill='none' onclick='openModalFiles(this)' stroke='currentColor' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>" +
            "<path stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13'></path>" +
          "</svg>" +
          "<svg onclick='deleteRecord(this)' class='w-6 h-6 cursor-pointer text-red-600 m-auto' xmlns='http://www.w3.org/2000/svg' class='h-6 w-6' fill='none' viewBox='0 0 24 24' stroke='currentColor'>"+
            "<path stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16' />"+
          "</svg></td></div>";
        }
      }
    }
    type_r_class = { className: "hidden", "targets": 2}
  }

  if ( $("#movements-records").length ) { 
    titleTable = "Registro de movimientos";
    buttons_or_sum = {
      data: "data_buttons",
      render:function(data, type, row)
      {
        rolUser = $("#rol-user").val();
        if (rolUser == "project_manager" || rolUser == "seller" || rolUser == "administrator_only_read" || rolUser == "archost_admin_only_read" || rolUser == "archost_admin_only_read_hours") {
          return "";
        } else {
          file = '';

          if (data[1] == true) {
            file = 'text-blue-600';
          };

          return "<div class='flex flex-row'><td><a target='_blank' href='/records/edit?record_id="+ data[0] +"'>"+ 
          "<svg class='w-6 h-6 cursor-pointer m-auto' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'>"+
            "<path stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z'></path>"+
          "</svg>"+
          "</a>" +
          "<svg class='" + file + " w-6 h-6 cursor-pointer mx-auto hover:bg-blue-200 icon-file' fill='none' onclick='openModalFiles(this)' stroke='currentColor' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>" +
            "<path stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13'></path>" +
          "</svg>" +
          "<svg onclick='deleteRecord(this)' class='w-6 h-6 cursor-pointer text-red-600 m-auto' xmlns='http://www.w3.org/2000/svg' class='h-6 w-6' fill='none' viewBox='0 0 24 24' stroke='currentColor'>"+
            "<path stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16' />"+
          "</svg></td></div>";
        }
      }
    }
    type_r_class = { className: "hidden", "targets": 2}
  }

  var buttonCommon = {
    exportOptions: {
      format: {
        body: function (data, row, column, node) {
          if ($("#business-projects-table").length) {
            node = $(node).find(".data-value");
            return node.is("input") ? node.val() : node.text().trim();
          }

          if ( $("#table-total-sum-account").length ) {
            dataNode = '';

            if ( row == 0 && column == 0) {
              titleRow = 1;
            }

            if ( $(node).find(".data-value").length ) {
              node = $(node).find(".data-value");

              dataNode = node.text().trim();
            }
            if ( $(node).hasClass("title-row") ) {
              dataNode = titleRow++;
            }

            return dataNode;
          }

          if(column > 0) {
            node = $(node).find(".data-value");
            return node.is("input") ? node.val() : node.text().trim();
          }else{
            return row + 1;
          }
        }
      }
    }
  };

  var buttonCommonExcel = {
    exportOptions: {
      format: {
        body: function (data, row, column, node) {
          node = $(node).find(".data-value");

          return node.is("input") ? node.val() : node.text().trim();
        }
      }
    }
  };

  if ($('#account-records').length) {
    titleTable = "Estado de Cuentas";
    ivaColumn = 15;
    total_balance = 0;
    buttons_or_sum = { data: 'type_r',
    render: function ( data, type, row, meta ) {
      
      if (data[0] == 'Ingreso') {
        total_balance += data[1];
      } else {
        total_balance -= data[1];
      }

      return (total_balance).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' });
    }},

    type_r_class = { className: "", "targets": 2};

    $("#account-name-status")[0].selectize.off()
    $("#account-name-status")[0].selectize.on("change", function (event) {
      $('#container-table-account').removeClass("hidden");
      $('#account-title-name').text('Nombre de la cuenta: '+ $("#account-name-status").text());
      $('#searched-word').val($('#account-name-status').val());
      total_balance = 0;
  
      $.ajax({
        url: "/records/get_initial_budget",
        method: "GET",
        dataType: "json",
        data: {
          account_id: $('#account-name-status').val()
        },
        error: function (xhr, status, error) {
          console.error('AJAX Error: ' + status + error);
        },
        success: function (response) {
          $('#account-title-budget').text(response['initial_budget'].toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' }));
        }
      });
  
      searchWord();
    });
  }

  if ($("#incomes-records").length || $("#expenses-records").length) {
    let typeEnterprise = $('#enterprise-record').text();

    switch (typeEnterprise) {
      case 'Archcost':
        ivaColumn = 15;
        var columnCurrency = [14,16]

        tableReports = $("#records-table").DataTable({
          serverSide: true,
          ajax: {
            url: '/records/rows',
            type: 'POST',
            data: {
              type_record: type_record,
              authenticity_token: AUTH_TOKEN
            },
            beforeSend: function(){
              // Here, manually add the loading message.
              $('#records-table > tbody').html(
                '<tr class="odd">' +
                  '<td valign="top" colspan="20" class="dataTables_empty">Cargando&hellip;</td>' +
                '</tr>'
              );
            },
            dataSrc: function(json){
              total_balance = json['records_after']
              sum_after = json['records_after']
              if ($('#account-records').length) {
                $('#account-total-budget').text(json['total_accumulated'].toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' }))
              }

              if ($("#incomes-records").length) {
                if (json['recordsTotal'] > localStorage.getItem('totalRecordsIncome') || localStorage.getItem('totalRecordsIncome') == null) {
                  localStorage.setItem('totalRecordsIncome', json['recordsTotal']);
                }
              }else{
                if (json['recordsTotal'] > localStorage.getItem('totalRecordsExpense') || localStorage.getItem('totalRecordsExpense') == null) {
                  localStorage.setItem('totalRecordsExpense', json['recordsTotal']);
                }
              }              
    
              return json.data;
            },
          },
          columnDefs: [
            { className: "association-record hidden", "targets": 19},
            type_r_class,
            { className: "record-id hidden", "targets": 0},
            { className: "px-1 py-1 border border-gray-200 text-center whitespace-nowrap text-xs font-medium", "targets": "_all"},
          ],
          columns: [
            { data: "id" },
            { data: "showed_id" },
            { data: "type_r",
              render: function ( data, type, row, meta ) {
                return data[0];
              }
            },
            { data: 'created_at' },
            { data: 'date' },
            { data: 'enterprise_r' },
            { data: 'project_r',
              render: function ( data, type, row, meta ) {
                if ( data == "oficina" ) {
                  const lower = data.toLowerCase();
                  dataCapitalize = data.charAt(0).toUpperCase() + lower.slice(1);
    
                  return dataCapitalize
                } else {
                  return data
                }
              }
            },
            { data: 'category_r',
              render: function ( data, type, row, meta ) {
                switch ( data ) {
                  case "Costos de venta":
                    data = "Costo de venta";
                    break;
                  case "Costos financieros":
                    data = "Costo integral financiero";
                    break;
                  default:
                    break;
                }
    
                return data
              }
            },
            { data: 'subcategory_r',
              render: function ( data, type, row, meta ) {
                if ( data == "preventas" || data == "saldos" || data == "comisiones sobre venta" || data == "rentas garantizadas" || data == "subcontratos" ) {
                  const lower = data.toLowerCase();
                  dataCapitalize = data.charAt(0).toUpperCase() + lower.slice(1);
                  
                  return dataCapitalize
                } else {
                  return data
                }
              }
            },
            { data: 'supplier_or_subcontract' },
            { data: 'client_r' },
            { data: 'concept_r' },
            { data: 'invoice_r' },
            { data: 'type_movement_r' },
            { data: 'account_r' },
            { data: 'total_amount_r',
              render: $.fn.dataTable.render.number( ',', '.', 2, '$' )},
            { data: 'value_iva_r',
              render: function ( data, type, row, meta ) {
              if (data[2]) {
                switch (data[3]) {
                  case "%":
                    amount = (data[0] / (1 + (data[1] / 100)))*(data[1] / 100);
                    amount = String(amount.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' }));
                    amount = amount + " ("+data[1]+"%)";
                    return amount
                    break;
                  case "$":
                    percentage = (((data[0]/(data[0] - data[1])) - 1)) * 100;
                    percentage = String(percentage.toFixed(2));
                    amount = String(data[1].toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' }));
                    amount = amount;
                    return amount+ " ("+percentage+"%)";
                    break;
                }
              } else {
                return "$0.00 (0%)";
              }
            }},
            { data: 'subtotal',
              render: $.fn.dataTable.render.number( ',', '.', 2, '$' )},
            { data: 'user.name'},
            { data: 'association' },
            buttons_or_sum,
          ],
          language: {
            lengthMenu: "Mostrar _MENU_ registros por página",
            zeroRecords: "Nada encontrado",
            info: "Mostrando página _PAGE_ de _PAGES_",
            infoEmpty: "No hay registros disponibles",
            infoFiltered: "(filtrado de _MAX_ registros)",
            oPaginate: {
              sFirst: "Primero",
              sPrevious: "Anterior",
              sNext: "Siguiente",
              sLast: "Último",
            },
          },
          "order": [[ 1, 'asc' ]],
          lengthMenu: [
            [10, 25, 50, 100, 500, 1000],
            [10, 25, 50, 100, 500, 1000],
          ],
          dom: "Bltip",
          buttons: [
            {
              text: 'Descargar',
              className: 'rounded-md border border-gray-300 shadow-sm px-8 py-4 bg-gray-200 text-sm font-medium text-black duration-500 hover:bg-gray-50 mb-2' ,
              init: function(api, node, config) {
                $(node).removeClass('dt-button');
             },
              action: function (e, dt, node, config) {
                event.preventDefault();
                $('#modal-download').toggle();

                var info = tableReports.page.info();
                var order = tableReports.order()[0];

                let $select = $(document.getElementsByClassName('processed_downloaded_file_pages')[0]);
                let selectize = $select[0].selectize;
                let type_record = '';
                let table_name = '';
                let hashInfo = {};
                hashInfo['page'] = info.page + 1;
                hashInfo['length'] = info.length;
                hashInfo['start'] = info.start;
                hashInfo['order_column'] = order[0];
                hashInfo['order_direction'] = order[1];
                hashInfo['total_records'] = info.recordsTotal;

                selectize.setValue(null);
                selectize.clearOptions();

                if ($("#incomes-records").length) {
                  type_record = 1;
                  table_name = 'Registro de ingresos';
                }

                if ($("#expenses-records").length) {
                  type_record = 2;
                  table_name = 'Registro de egresos';
                }

                hashInfo['type_record'] = type_record;
                hashInfo['table_name'] = table_name;

                selectize.addOption({
                  value: 0,
                  text: 'Página ' + (info.page + 1) + ' de ' + info.pages + ' ('+ tableReports.data().length +' registros)',
                });

                selectize.addOption({
                  value: 1,
                  text: 'Filtro completo (' + info.pages + ' pags) ('+ info.recordsTotal +' registros)',
                });

                selectize.addOption({
                  value: 2,
                  text: 'Toda la tabla',
                });

                $('#processed_downloaded_file_hash_info').val(JSON.stringify(hashInfo));
 
              }
          }
          ],
          "drawCallback":function(){
            if ($("#expenses-records").length || $("#incomes-records").length) {
              var api = this.api()
              $(api.column(14).footer()).html(
                'Total: '
              )
              $(api.column(15).footer()).html(
                sum(api.column(15, {page:'current'}).data()).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' })
              )

              if ($('#filter-warning').is(":visible")) {
                $('#filter-warning').hide();
                $("#filter-success").css('display', 'flex');
              }

              if ($('#filter-add').is(":visible")) {
                $('#filter-add').hide();
                $("#filter-success").css('display', 'flex');
              }
            }
          }
        });

        break;
      case 'Devso':
        ivaColumn = 16;
        var columnCurrency = [15,17]

        tableReports = $("#records-table").DataTable({
          serverSide: true,
          ajax: {
            url: '/records/rows',
            type: 'POST',
            data: {
              type_record: type_record,
              authenticity_token: AUTH_TOKEN
            },
            beforeSend: function(){
              // Here, manually add the loading message.
              $('#records-table > tbody').html(
                '<tr class="odd">' +
                  '<td valign="top" colspan="20" class="dataTables_empty">Cargando&hellip;</td>' +
                '</tr>'
              );
            },
            dataSrc: function(json){
              total_balance = json['records_after']
              sum_after = json['records_after']
              if ($('#account-records').length) {
                $('#account-total-budget').text(json['total_accumulated'].toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' }))
              }
              
              if ($("#incomes-records").length) {
                if (json['recordsTotal'] > localStorage.getItem('totalRecordsIncome') || localStorage.getItem('totalRecordsIncome') == null) {
                  localStorage.setItem('totalRecordsIncome', json['recordsTotal']);
                }
              }else{
                if (json['recordsTotal'] > localStorage.getItem('totalRecordsExpense') || localStorage.getItem('totalRecordsExpense') == null) {
                  localStorage.setItem('totalRecordsExpense', json['recordsTotal']);
                }
              }
    
              return json.data;
            },
          },
          columnDefs: [
            { className: "association-record hidden", "targets": 20},
            type_r_class,
            { className: "record-id hidden", "targets": 0},
            { className: "px-1 py-1 border border-gray-200 text-center whitespace-nowrap text-xs font-medium", "targets": "_all"},
          ],
          columns: [
            { data: "id" },
            { data: "showed_id" },
            { data: "type_r",
              render: function ( data, type, row, meta ) {
                return data[0];
              }
            },
            { data: 'created_at' },
            { data: 'date' },
            { data: 'enterprise_r' },
            { data: 'project_r',
              render: function ( data, type, row, meta ) {
                if ( data == "oficina" ) {
                  const lower = data.toLowerCase();
                  dataCapitalize = data.charAt(0).toUpperCase() + lower.slice(1);
    
                  return dataCapitalize
                } else {
                  return data
                }
              }
            },
            { data: 'category_r',
              render: function ( data, type, row, meta ) {
                switch ( data ) {
                  case "Costos de venta":
                    data = "Costo de venta";
                    break;
                  case "Costos financieros":
                    data = "Costo integral financiero";
                    break;
                  default:
                    break;
                }
    
                return data
              }
            },
            { data: 'subcategory_r',
              render: function ( data, type, row, meta ) {
                if ( data == "preventas" || data == "saldos" || data == "comisiones sobre venta" || data == "rentas garantizadas" || data == "subcontratos" ) {
                  const lower = data.toLowerCase();
                  dataCapitalize = data.charAt(0).toUpperCase() + lower.slice(1);
                  
                  return dataCapitalize
                } else {
                  return data
                }
              }
            },
            {data: 'supplier_r'},
            { data: 'unit_r' },
            { data: 'client_r' },
            { data: 'concept_r' },
            { data: 'invoice_r' },
            { data: 'type_movement_r' },
            { data: 'account_r' },
            { data: 'total_amount_r',
              render: $.fn.dataTable.render.number( ',', '.', 2, '$' )},
            { data: 'value_iva_r',
              render: function ( data, type, row, meta ) {
              if (data[2]) {
                switch (data[3]) {
                  case "%":
                    amount = (data[0] / (1 + (data[1] / 100)))*(data[1] / 100);
                    amount = String(amount.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' }));
                    amount = amount + " ("+data[1]+"%)";
                    return amount
                    break;
                  case "$":
                    percentage = (((data[0]/(data[0] - data[1])) - 1)) * 100;
                    percentage = String(percentage.toFixed(2));
                    amount = String(data[1].toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' }));
                    amount = amount;
                    return amount+ " ("+percentage+"%)";
                    break;
                }
              } else {
                return "$0.00 (0%)";
              }
            }},
            { data: 'subtotal',
              render: $.fn.dataTable.render.number( ',', '.', 2, '$' )},
            { data: 'user.name'},
            { data: 'association' },
            buttons_or_sum,
          ],
          language: {
            lengthMenu: "Mostrar _MENU_ registros por página",
            zeroRecords: "Nada encontrado",
            info: "Mostrando página _PAGE_ de _PAGES_",
            infoEmpty: "No hay registros disponibles",
            infoFiltered: "(filtrado de _MAX_ registros)",
            oPaginate: {
              sFirst: "Primero",
              sPrevious: "Anterior",
              sNext: "Siguiente",
              sLast: "Último",
            },
          },
          "order": [[ 1, 'asc' ]],
          lengthMenu: [
            [10, 25, 50, 100, 500, 1000],
            [10, 25, 50, 100, 500, 1000],
          ],
          dom: "Bltip",
          buttons: [
            {
              text: 'Descargar',
              className: 'rounded-md border border-gray-300 shadow-sm px-8 py-4 bg-gray-200 text-sm font-medium text-black duration-500 hover:bg-gray-50 mb-2' ,
              init: function(api, node, config) {
                $(node).removeClass('dt-button');
             },
              action: function (e, dt, node, config) {
                event.preventDefault();
                $('#modal-download').toggle();

                var info = tableReports.page.info();
                var order = tableReports.order()[0];

                let $select = $(document.getElementsByClassName('processed_downloaded_file_pages')[0]);
                let selectize = $select[0].selectize;
                let type_record = '';
                let table_name = '';
                let hashInfo = {};
                hashInfo['page'] = info.page + 1;
                hashInfo['length'] = info.length;
                hashInfo['start'] = info.start;
                hashInfo['order_column'] = order[0];
                hashInfo['order_direction'] = order[1];
                hashInfo['total_records'] = info.recordsTotal;

                selectize.setValue(null);
                selectize.clearOptions();

                if ($("#incomes-records").length) {
                  type_record = 1;
                  table_name = 'Registro de ingresos';
                }

                if ($("#expenses-records").length) {
                  type_record = 2;
                  table_name = 'Registro de egresos';
                }

                hashInfo['type_record'] = type_record;
                hashInfo['table_name'] = table_name;

                selectize.addOption({
                  value: 0,
                  text: 'Página ' + (info.page + 1) + ' de ' + info.pages + ' ('+ tableReports.data().length +' registros)',
                });

                selectize.addOption({
                  value: 1,
                  text: 'Filtro completo (' + info.pages + ' pags) ('+ info.recordsTotal +' registros)',
                });

                $('#processed_downloaded_file_hash_info').val(JSON.stringify(hashInfo));
 
              }
          }
          ],
          "drawCallback":function(){
            if ($("#expenses-records").length || $("#incomes-records").length) {
              var api = this.api()
              $(api.column(15).footer()).html(
                'Total: '
              )
              $(api.column(16).footer()).html(
                sum(api.column(16, {page:'current'}).data()).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' })
              )

              if ($('#filter-warning').is(":visible")) {
                $('#filter-warning').hide();
                $("#filter-success").css('display', 'flex');
              }

              if ($('#filter-add').is(":visible")) {
                $('#filter-add').hide();
                $("#filter-success").css('display', 'flex');
              }
            }
          }
        });
        break;
      default:
        break;
    }

    var  columnsLengthTable = tableReports.columns().count();
  }

  if ($("#movements-records").length) {
    var columnCurrency = [8]
    columnsVisible = range(1,9)

    tableReports = $("#records-table").DataTable({
      serverSide: true,
      ajax: {
        url: '/records/rows',
        type: 'POST',
        data: {
          type_record: type_record,
          authenticity_token: AUTH_TOKEN
        },
        beforeSend: function(){
          // Here, manually add the loading message.
          $('#records-table > tbody').html(
            '<tr class="odd">' +
              '<td valign="top" colspan="19" class="dataTables_empty">Cargando&hellip;</td>' +
            '</tr>'
          );
        },
        dataSrc: function(json){
          total_balance = json['records_after']
          sum_after = json['records_after']
          if ($('#account-records').length) {
            $('#account-total-budget').text(json['total_accumulated'].toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' }))
          }

          return json.data;
        },
      },
      columnDefs: [
        type_r_class,
        { className: "record-id hidden", "targets": 0},
        { className: "px-1 py-1 border border-gray-200 text-center whitespace-nowrap text-xs font-medium", "targets": "_all"},
      ],
      columns: [
        { data: "id" },
        { data: "showed_id" },
        { data: "type_r",
          render: function ( data, type, row, meta ) {
            return data[0];
          }
        },
        { data: 'created_at' },
        { data: 'date' },
        { data: 'concept_r' },
        { data: 'type_movement_r' },
        { data: 'output_account_r' },
        { data: 'input_account_r' },
        { data: 'total_amount_r',
          render: $.fn.dataTable.render.number( ',', '.', 2, '$' )},
        { data: 'user.name'},
        buttons_or_sum,
      ],
      language: {
        lengthMenu: "Mostrar _MENU_ registros por página",
        zeroRecords: "Nada encontrado",
        info: "Mostrando página _PAGE_ de _PAGES_",
        infoEmpty: "No hay registros disponibles",
        infoFiltered: "(filtrado de _MAX_ registros)",
        oPaginate: {
          sFirst: "Primero",
          sPrevious: "Anterior",
          sNext: "Siguiente",
          sLast: "Último",
        },
      },
      "order": [[ 1, 'asc' ]],
      lengthMenu: [
        [10, 25, 50, 100, 500, 1000],
        [10, 25, 50, 100, 500, 1000],
      ],
      dom: "Bltip",
      buttons: [
        {
            extend: 'copyHtml5',
            exportOptions: {
              columns: function(idx, data, node) {
                columnsVisibleCopy = range(1,9)
                if ($.inArray(idx, columnsVisibleCopy) < 0) {
                  return false;
                }
                return true;
              },
            },
        },
        {
            extend: 'excelHtml5',
            customize: function ( xlsx ) {
              var sheet = xlsx.xl.worksheets['sheet1.xml'];
           
              $('row:eq(0) c', sheet).attr( 's', '50' );
              $('row:eq(1) c', sheet).attr( 's', '50' );
              $('row c[r^="I"]', sheet).attr( 's', 57);
            },
            title: titleTable,
            messageTop:  function() {
              return `Fecha de exportación: ${datetime}
              Fecha inicial: ${initialDateTable}
              Fecha Final: ${finalDateTable}`
            },
            exportOptions: {
              orthogonal: null,
              columns: function(idx, data, node) {
                columnsVisibleExcel = range(1,9)
                if ($.inArray(idx, columnsVisibleExcel) < 0) {
                  return false;
                }
                return true;
              },
              format: {
                body: function ( data, row, column ) {
                  var momentDate = moment(data, 'DD-MM-YYYY', true);
                  if (momentDate.isValid()) {
                    return momentDate.format('YYYY-MM-DD');
                  }

                  if (columnCurrency.includes(column)) {
                    price = data.toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' });
                    return String(price)
                  }

                  if (column == typeColumn) {
                    return data[0];
                  }

                  return data;
                }
              }
            },
        },
        {
          extend: 'pdfHtml5',
          orientation: 'landscape',
          pageSize: 'LEGAL',
          title: function() {
            return `${titleTable}_${numFile}`
          },
          messageTop: function() {
            return `Fecha de exportación: ${datetime}
            Fecha inicial: ${initialDateTable}
            Fecha Final: ${finalDateTable}`
          },
          exportOptions: {
            columns: function(idx, data, node) {
              if ($.inArray(idx, columnsVisible) < 0) {
                return false;
              }
              return true;
            },
            stripHtml: false,
            stripNewlines: false
          },
          customize: function ( doc ) {
            doc.content[0].style = {bold: true, alignment: "center", fontSize: "25"};
            doc.styles.tableFooter.fontSize = 8;

            for (let i = 0; i <  doc.content[2].table.body[0].length; i++) {
              doc.content[2].table.body[0][i].text = doc.content[2].table.body[0][i].text.replace("<br>","\n");
            }

            if ( img64 != '' ) {
              doc.content.splice( 1, 0, {
                margin: [ 0, 0, 0, 12 ],
                alignment: 'left',
                image: img64,
                width: 170,
                height: 30
              } );
            }
          }
        } 
      ],
      "drawCallback":function(){
        if ($("#expenses-records").length || $("#incomes-records").length) {
          var api = this.api()
          $(api.column(14).footer()).html(
            'Total: '
          )
          $(api.column(15).footer()).html(
            sum(api.column(15, {page:'current'}).data()).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' })
          )
        }
      }
    });
    var  columnsLengthTable = tableReports.columns().count();
  }

  $("#initial_date").on("change", function() {
    let initialDate = $('#initial_date').val();
    initialDateTable = initialDate != '' ? initialDate : 'N/A';
  })

  $("#final_date").on("change", function() {
    let finalDate = $('#final_date').val();
    finalDateTable = finalDate != '' ? finalDate : 'N/A';
  })

  $(".buttons-pdf").on("click", function () {
    total_balance = sum_after;
    if(isOtherPage) {
      numLaps = 1;
      if ( (columnsVisible[columnsVisible.length -1] + 12) < columnsLengthTable) {
        columnsLength = columnsVisible.length + 11;
      }else{
        if($('#account-records').length){
          columnsLength = columnsVisible[columnsVisible.length -1] + ((columnsLengthTable - columnsVisible[columnsVisible.length -1]));
        }else{
          columnsLength = columnsVisible[columnsVisible.length -1] + ((columnsLengthTable - columnsVisible[columnsVisible.length -1]) - 1);
        }
        isOtherPage = false;
      }
      numFile++;
      columnInitial = columnsVisible[columnsVisible.length -1] + 1;
      columnsVisible = [];
      for (let i = columnInitial; i < (columnsLength); i++) {
        if (numLaps <= 1) {
          columnsVisible.push(1);
          i -= 1;
          numLaps = 2;
        }else{
         columnsVisible.push(i);
        }
      }
      tableReports.buttons('.buttons-pdf').trigger();
    }else{
      isOtherPage = true;
      columnsVisible = range(1,12);
      numFile = 1;
    }
  });

  function sum(data) { 
    return data.flatten().reduce( function ( a, b ) {

      if ( $("#business-projects-table").length && typeof b != 'string' && $(b).find("div") ) {
        b = $(b).find("div").text();
      }
      
      if ( typeof a === 'string' ) {
        a = a.replace(/[^\d.-]/g, '') * 1;
      }

      if ( typeof b === 'string' ) {
        b = b.replace(/[^\d.-]/g, '') * 1;
      }
      return a + b;
    }, 0 );
  }

  var columnSelected = 0;
  var wordSelected = "";

  $("#searched-word").keypress(function (e) {
    var code = (e.keyCode ? e.keyCode : e.which);
    if (code == 13) {
      searchWord();
    }
  });

  $('#search-by-word').click(function () {
    searchWord();
  });

  const searchWord = () => {
    if ($("#incomes-records").length || $("#expenses-records").length || $("#movements-records").length) {
      createHashFilter();
      $('#search-by-word').removeClass( "animacion" );
    }

    wordSelected = $('#searched-word').val();

    tableReports
      .search("");
    tableReports
      .search(wordSelected)
      .draw();
  }

  function createHashFilter() {
    let hashInfo = {};
    let initialDate = $('#initial_date').val();
    let finalDate = $('#final_date').val();
    hashInfo['initial_date'] = initialDate;
    hashInfo['final_date'] = finalDate;
    let hashFilter = {};
    let column = '';
    let value = '';

    $("#filter-conteiner > div").each(function( index ) {
      column = $( this ).find('.column-filter').text();
      value = $( this ).find('.column-value').text();

      switch (column) {
        case 'showed_id':
          if (hashFilter[column] == null) {
            hashFilter[column] = [];
          };

          hashFilter[column].push(value);
          break;

        case 'total_amount_r':
          if (hashFilter[column] == null) {
            hashFilter[column] = [];
          };

          hashFilter[column].push(value.split('$').join('').split(',').join(''));
          break;

        case 'subcontract_r':
          if (hashFilter['unit_r'] == null) {
            hashFilter['unit_r'] = [];
          };

          hashFilter['unit_r'].push(value);
          break;

        case 'work_r':
          if (hashFilter['client_r'] == null) {
            hashFilter['client_r'] = [];
          };

          hashFilter['client_r'].push(value);
          break;
        default:
          if (hashFilter[column] == null) {
            hashFilter[column] = [];
          };
          
          hashFilter[column].push(value);
          break;
      }
    });

    hashInfo['hash_filter'] = hashFilter;
    hashFilter = JSON.stringify(hashFilter);
    $('#searched-word').val(JSON.stringify(hashInfo));
  }

  $('#search-registry-dates').click(function () {
    searchDate();
  });

  const searchDate = () => {
    createHashFilterHours();
    $('#search-registry-dates').removeClass( "animacion" );

    sendMessage = $('#search-text').val();

    tableRegistry
      .search("");
    tableRegistry
      .search(sendMessage)
      .draw();
  }

  function createHashFilterHours() {
    let hashInfo = {};
    let initialDate = $('#initial-registry-date').val();
    let finalDate = $('#final-registry-date').val();
    hashInfo['initial_date'] = initialDate;
    hashInfo['final_date'] = finalDate;
    let hashFilter = {};
    let column = '';
    let value = '';

    $("#filter-conteiner > div").each(function( index ) {
      column = $( this ).find('.column-filter').text();
      value = $( this ).find('.column-value').text();

      switch (column) {
        case 'work_r':
          if (hashFilter['client_r'] == null) {
            hashFilter['client_r'] = [];
          };

          hashFilter['client_r'].push(value);
          break;
        default:
          if (hashFilter[column] == null) {
            hashFilter[column] = [];
          };
          
          hashFilter[column].push(value);
          break;
      }
    });

    hashInfo['hash_filter'] = hashFilter;
    hashFilter = JSON.stringify(hashFilter);
    $('#search-text').val(JSON.stringify(hashInfo));
  }

  $('.planning_category').on("change", function(){
    var type = $(this).val();
    if(type.includes('P')) {
      $('.catalog_planning').val(type);
    }else{
      if(type.includes('inmobiliary_project') || type.includes('business_unit')) {
        $('.catalog_record').val(type);
      }
    }
    let business_unit = $('#catalog_business_unit').val();
    $.ajax({
      url: "/catalogs/subcategories",
      method: "GET",  
      dataType: "json",
      data: {
        catalog: {
          type: type,
          business_unit: business_unit 
        }
      },
      error: function (xhr, status, error) {
        console.error('AJAX Error: ' + status + error);
      },
      success: function (response) {
        let subcategories = response["subcategories"];
        let table = response["table"];
        let checked;
        if (response['catalogs_projects_modify']) {
          hidden = ''
          disabled = ''
        } else {
          hidden = 'hidden'
          disabled = 'disabled'
        }
        if (table == 'planning') {
          $("#planning-subcategories").empty();
          for(var i=0; i< subcategories.length; i++){
            checked = subcategories[i]['visible'];
            if(checked) {
              checked = 'checked';
            }else{
              checked = '';
            }

            $("#planning-subcategories").append('' +
            '<tr class="hover:bg-grey-lighter odd">' +
              '<td class="py-1 px-6 border-b border-grey-light text-sm">' +
                '<input class="category-name" type="hidden" value="' + response['type']  +'" readonly>'+
                '<input class="subcategory-id" type="hidden" value="' +  subcategories[i]['_id']['$oid'] + '" readonly>'+
                '<span class="subcategory-name">' +
                  subcategories[i]['name'] +
                '</span>' +
              '</td>' +
              '<td class="py-1 px-6 border-b border-grey-light text-center">' +
                '<input class="w-5 h-5 mt-1" type="checkbox" onclick="updateShowSubcategory(this)" '+ checked  +' ' + disabled +'>' +
              '</td>'+
              '<td class="py-1 px-6 border-b border-grey-light text-center space-x-3">'+
                '<div class="flex ' + hidden +'">'+
                  '<button class="hover:bg-yellow-200 rounded p-1" onclick="updateNameSubcategory(this)">'+
                    '<svg class="w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">'+
                      '<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"></path>' +
                    '</svg>'+
                  '</button>'+
                  '<button class="hover:bg-yellow-200 rounded p-1"  onclick="updateDeleteSubcategory(this)">'+
                    '<svg class="w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">'+
                      '<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path>'+
                    '</svg>'+
                  '</button>'+
                '</div>'+
              '</td>'+
            '</tr>'+
          '');
          } 
        }
        if (table == 'record') {
          $("#record-subcategories").empty();
          if (response['subcontract'] == true) {
            $("#record-subcategories").append('' +
            '<tr class="hover:bg-grey-lighter odd">' +
              '<td class="py-1 px-6 border-b border-grey-light text-sm">' +
                '<span class="subcategory-name">Subcontratos</span>' +
              '</td>' +
              '<td class="py-1 px-6 border-b border-grey-light text-center">' +
              '</td>'+
              '<td class="py-1 px-6 border-b border-grey-light text-center space-x-3">'+
              '</td>'+
            '</tr>'+
          '');
          }
          if(subcategories.length > 0){
            for(var i=0; i< subcategories.length; i++){
              checked = subcategories[i]['visible'];
              if(checked) {
                checked = 'checked';
              }else{
                checked = '';
              }
              sign = subcategories[i]['is_positive']
              if( response['category_name'] == 'Costos financieros'){
                if (sign == true) {
                  sign = '<span class="subcategory-sign mr-1">(+)</span>'
                };
                if (sign == false) {
                  sign = '<span class="subcategory-sign mr-1">(-)</span>'
                };
              }else{
                sign = ''
              }
              $("#record-subcategories").append('' +
              '<tr class="hover:bg-grey-lighter odd">' +
                '<td class="py-1 px-6 border-b border-grey-light text-sm">' +
                  '<input class="category-name" type="hidden" value="' + response['type']  +'" readonly>'+
                  '<input class="subcategory-id" type="hidden" value="' +  subcategories[i]['_id']['$oid'] + '" readonly>'+
                  sign +
                  '<span class="subcategory-name">' +
                    subcategories[i]['name'] +
                  '</span>' +
                '</td>' +
                '<td class="py-1 px-6 border-b border-grey-light text-center">' +
                  '<input class="w-5 h-5 mt-1" type="checkbox" onclick="updateShowSubcategory(this)" '+ checked  +' ' + disabled + '>' +
                '</td>'+
                '<td class="py-1 px-6 border-b border-grey-light text-center space-x-3">'+
                  '<div class="flex ' + hidden + '">'+
                    '<button class="hover:bg-yellow-200 rounded p-1" onclick="updateNameSubcategory(this)">'+
                      '<svg class="w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">'+
                        '<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"></path>' +
                      '</svg>'+
                    '</button>'+
                    '<button class="hover:bg-yellow-200 rounded p-1" onclick="updateDeleteSubcategory(this)">'+
                      '<svg class="w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">'+
                        '<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path>'+
                      '</svg>'+
                    '</button>'+
                  '</div>'+
                '</td>'+
              '</tr>'+
            '');
            }
          }else{
            if($("#no-records").length == 0) {
              $("#record-subcategories").append('<tr id="no-records"><td colspan="3" class="text-center py-3">No hay registros disponibles.</td></tr>');
            }
          }
        }
      }
    });
  });

  if($('#table-clients').length) {
    var tableClients = $('#table-clients').DataTable({
      scrollY:        '68vh',
      scrollX:        true,
      scrollCollapse: true,
      autoWidth: false,
      "language": {
        "lengthMenu": "Mostrar _MENU_ registros por página",
        "zeroRecords": "Nada encontrado",
        "info": "Mostrando página _PAGE_ de _PAGES_",
        "infoEmpty": "No hay registros disponibles",
        "infoFiltered": "(filtrado de _MAX_ registros)",
        "sSearch": "Buscar:",
        "oPaginate": {
          "sFirst": "Primero",
          "sPrevious": "Anterior",
          "sNext": "Siguiente",
          "sLast": "Último"
        }
      },
      dom: 'Blftip',
      buttons: [
        {
          extend:'copyHtml5',
          title: "Clientes",
          messageTop: `Fecha de exportación: ${datetime}\n`,
          exportOptions: { 
            columns: [1, 2, 3, 4 , 5, 6] 
          },
        },
        {
          extend:'excelHtml5',
          title: "Clientes",
          messageTop: `Fecha de exportación: ${datetime}\n`,
          exportOptions: { 
            columns: [1, 2, 3, 4 , 5, 6] 
          },
        },
        {
          extend: 'pdfHtml5',
          title: "Clientes",
          messageTop: `Fecha de exportación: ${datetime}`,
          exportOptions:{
            columns: [1, 2, 3, 4, 5, 6],
            rows: ':visible'
          },
          customize: function ( doc ) {
            if ( img64 != '' ) {
              doc.content.splice( 1, 0, {
                margin: [ 0, 0, 0, 12 ],
                alignment: 'left',
                image: img64,
                width: 170,
                height: 30
              } );
            }
          }
        }
      ],
    });
  }


  if($('#table-subcontracts').length) {
    $('#table-subcontracts').DataTable({
      scrollY:        '68vh',
      scrollX:        true,
      scrollCollapse: true,
      "language": {
        "lengthMenu": "Mostrar _MENU_ registros por página",
        "zeroRecords": "Nada encontrado",
        "info": "Mostrando página _PAGE_ de _PAGES_",
        "infoEmpty": "No hay registros disponibles",
        "infoFiltered": "(filtrado de _MAX_ registros)",
        "sSearch": "Buscar:",
        "oPaginate": {
          "sFirst": "Primero",
          "sPrevious": "Anterior",
          "sNext": "Siguiente",
          "sLast": "Último"
        }
      },
      dom: 'Blftip',
      buttons: [
        {
          extend:'copyHtml5',
          title: "Proveedores",
          messageTop: `Fecha de exportación: ${datetime}\n`,
          exportOptions: { 
            columns: [1, 2, 3, 4 , 5, 6] 
          },
        },
        {
          extend:'excelHtml5',
          title: "Proveedores",
          messageTop: `Fecha de exportación: ${datetime}\n`,
          exportOptions: { 
            columns: [1, 2, 3, 4 , 5, 6] 
          },
        },
        {
          extend: 'pdfHtml5',
          title: "Proveedores",
          messageTop: `Fecha de exportación: ${datetime}`,
          exportOptions:{
            columns: [1, 2, 3, 4, 5, 6],
            rows: ':visible'
          },
          customize: function ( doc ) {
            if ( img64 != '' ) {
              doc.content.splice( 1, 0, {
                margin: [ 0, 0, 0, 12 ],
                alignment: 'left',
                image: img64,
                width: 170,
                height: 30
              } );
            }
          }
        }
      ],
    });
  }
      
  let tableRegistry =$('#table-registry').DataTable({
    serverSide: true,
    ajax: {
      url: '/record_hourlys/rows',
      type: 'POST',
      data: {
        authenticity_token: AUTH_TOKEN
      },
      beforeSend: function(){
        // Here, manually add the loading message.
        $('#table-registry > tbody').html(
          '<tr class="odd">' +
            '<td valign="top" colspan="19" class="dataTables_empty">Cargando&hellip;</td>' +
          '</tr>'
        );
      },
      dataSrc: function(json){
        total_balance = json['records_after']
        sum_after = json['records_after']
        if ($('#account-records').length) {
          $('#account-total-budget').text(json['total_accumulated'].toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' }))
        }

        if (localStorage.getItem('totalHours') == null || json['recordsTotal'] > localStorage.getItem('totalHours')) {
          localStorage.setItem('totalHours', json['recordsTotal']);
        }

        return json.data;
      },
    },
    columnDefs: [
      { className: "record_hour_id hidden", "targets": 0},
      { className: "px-1 py-1 border border-gray-200 text-center whitespace-nowrap text-xs font-medium hidden", "targets": 9},
      { className: "px-1 py-1 border border-gray-200 text-center whitespace-nowrap text-xs font-medium", "targets": "_all"},
    ],
    columns: [
      { data: "id" },
      { data: "showed_id" },
      { data: 'created_at' },
      { data: 'date' },
      { data: 'project_r',
        render: function ( data, type, row, meta ) {
          if ( data == "oficina" ) {
            const lower = data.toLowerCase();
            dataCapitalize = data.charAt(0).toUpperCase() + lower.slice(1);

            return dataCapitalize
          } else {
            return data
          }
        }
      },
      { data: 'status2_r' },
      { data: 'subphase_r' },
      { data: 'description' },
      { data: 'hours' },
      { data: 'hours',
      render: function ( data, type, row, meta ) {
        let element = data.split(":");
        let total_minutes = (parseInt(element[0]) * 60) + parseInt(element[1]);

        return (total_minutes/60).toFixed(2);
      }
    },
      { data: 'team_work_user_r' },
      { data: 'user_r' },
      { data: 'role_r',
        render:function(data, type, row, meta){
        user_id = $("#user-id").val();
        team_work_user_id = $('#team-work-user-id').val();
        user_role = $("#user-role").val();
        let can_edit = $('#edit-records').val(); //OBTENER SI EL USUARIO TIENE PERMISO DE EDITAR HORAS (CHECKBOX AL DAR DE ALTA USUARIO)

        if (user_id == data[1] || team_work_user_id == data[2] || user_role == 'archost_super_admin' || can_edit == 'true'){
          return '<div class="flex flex-row"><td><a href="/edit_registry?record_hourly_id='+data[0]+'">' + 
          '<svg class="w-6 h-6 cursor-pointer m-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">' +
            '<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"></path>' +
          '</svg>' +
          '</a>' +
          '<button type="button" class="hover:bg-yellow-200 rounded p-1" onclick="deleteRecordHours(this)">' +
            '<svg class="w-6 h-6 cursor-pointer text-red-600 m-auto" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">' +
              '<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />' +
            '</svg>' +
          '</button></td></div>';
        } else {
          return '';
        }
        }
      },
    ],
    language: {
      lengthMenu: "Mostrar _MENU_ registros por página",
      zeroRecords: "Nada encontrado",
      info: "Mostrando página _PAGE_ de _PAGES_",
      infoEmpty: "No hay registros disponibles",
      infoFiltered: "(filtrado de _MAX_ registros)",
      oPaginate: {
        sFirst: "Primero",
        sPrevious: "Anterior",
        sNext: "Siguiente",
        sLast: "Último",
      },
    },
    "order": [[ 1, 'asc' ]],
    lengthMenu: [
      [10, 25, 50, 100, 500, 1000],
      [10, 25, 50, 100, 500, 1000],
    ],
    dom: 'Bltip',
    buttons: [
      {
        text: 'Descargar',
        className: 'rounded-md border border-gray-300 shadow-sm px-8 py-4 bg-gray-200 text-sm font-medium text-black duration-500 hover:bg-gray-50 mb-2' ,
        init: function(api, node, config) {
          $(node).removeClass('dt-button');
       },
        action: function (e, dt, node, config) {
          event.preventDefault();
          $('#modal-download').toggle();

          var info = tableRegistry.page.info();
          var order = tableRegistry.order()[0];

          let $select = $(document.getElementsByClassName('processed_downloaded_file_pages')[0]);
          let selectize = $select[0].selectize;

          let hashInfo = {
            "page": info.page + 1,
            "length": info.length,
            "start": info.start,
            "order_column": order[0],
            "order_direction": order[1],
            "total_records": info.recordsTotal,
            "table_name": 'Registro de horas'
        };

          selectize.setValue(null);
          selectize.clearOptions();

          selectize.addOption({
            value: 0,
            text: 'Página ' + (info.page + 1) + ' de ' + info.pages + ' ('+ tableRegistry.data().length +' registros)',
          });

          selectize.addOption({
            value: 1,
            text: 'Filtro completo (' + info.pages + ' pags) ('+ info.recordsTotal +' registros)',
          });

          selectize.addOption({
            value: 2,
            text: 'Toda la tabla',
          });

          $('#processed_downloaded_file_hash_info').val(JSON.stringify(hashInfo));

        }
    }
    ],
    "drawCallback":function(){
      var api = this.api()
      $(api.column(7).footer()).html(
        'Total: '
      )

      $(api.column(8).footer()).html(
        sumHours(api.column(8, {page:'current'}).data())
      );

      if ($('#filter-warning').is(":visible")) {
        $('#filter-warning').hide();
        $("#filter-success").css('display', 'flex');
      }

      if ($('#filter-add').is(":visible")) {
        $('#filter-add').hide();
        $("#filter-success").css('display', 'flex');
      }
    }
  });

  function sumHours(arrayHours) {
    let total_minutes = 0;
    arrayHours.each(function(hour) {
      let element = hour.split(":");
      total_minutes += (parseInt(element[0]) * 60) + parseInt(element[1])
    });

    hours = Math.floor(total_minutes/60);
    minutes = total_minutes - (hours*60);

    if (minutes < 10) {
      minutes = '0' + String(minutes);
    };

    total_time = String(hours) + ':' + String(minutes);
    return total_time;
  };

  if($('#table-subcontractor-jobs').length) {
    var tableSubcontractJobs = $('#table-subcontractor-jobs').DataTable({
      scrollY:        '68vh',
      scrollX:        true,
      scrollCollapse: true,
      "language": {
        "lengthMenu": "Mostrar _MENU_ registros por página",
        "zeroRecords": "Nada encontrado",
        "info": "Mostrando página _PAGE_ de _PAGES_",
        "infoEmpty": "No hay registros disponibles",
        "infoFiltered": "(filtrado de _MAX_ registros)",
        "sSearch": "Buscar:",
        "oPaginate": {
          "sFirst": "Primero",
          "sPrevious": "Anterior",
          "sNext": "Siguiente",
          "sLast": "Último"
        }
      },
      dom: 'Blftip',
      buttons: [],
      "drawCallback":function(){
        var api = this.api();
        $(api.column(4).footer()).html('Total: ');
        $(api.column(5).footer()).html('  ' +
        sum(api.column(5, {page:'current'}).data()).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' }) + '  '
        )
        $(api.column(6).footer()).html('  ' +
        sum(api.column(6, {page:'current'}).data()).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' }) + '  '
        )
        $(api.column(7).footer()).html('  ' +
        sum(api.column(7, {page:'current'}).data()).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' }) + '  '
        )
      }
    });

    $('#table-subcontractor-jobs tbody').on('click', '.job-id-button', function (event) {
      tableSubcontractJobs.$('tr.selected').removeClass('selected');
      let tr = $(this).closest("tr");
      tr.addClass('selected');      
  });
  }
  
  if($('#table-catalogs-projects').length) {
    $('#table-catalogs-projects').DataTable({
      scrollY:        '30vh',
      scrollX:        true,
      scrollCollapse: true,
      "language": {
        "lengthMenu": "",
        "zeroRecords": "Nada encontrado",
        "info": "",
        "infoEmpty": "",
      },
      searching: false,
      paging: false,
      dom: 'Blftip',
      buttons: [
      ],
      "lengthMenu": [[-1], ["All"]],
      aaSorting: [],
    });
  }

  if($('#table-catalogs-records').length) {
    $('#table-catalogs-records').DataTable({
      scrollY:        '30vh',
      scrollX:        true,
      scrollCollapse: true,
      "language": {
        "lengthMenu": "",
        "zeroRecords": "Nada encontrado",
        "info": "",
        "infoEmpty": "No hay registros disponibles",
      },
      searching: false,
      paging: false,
      dom: 'Blftip',
      buttons: [
      ],
      "lengthMenu": [[-1], ["All"]],
      aaSorting: [],
    });
  }
  
  if($('#one').length && $('#two').length) {
    document.getElementById("one").onclick = function() {showTab(this)};
    document.getElementById("two").onclick = function() {showTab(this)};
  }
  
  function showTab(e) {
    let selectType = $(e).attr("data-select");
    if (selectType == 'one') {
        $("#tabs2,#tabs3").hide();
        $("#tabs1").show();
        $("#one").addClass('text-blue-800 active');
        $("#two,#three").removeClass('text-blue-800 active');
  
    } else if (selectType == 'two') {
  
      $("#tabs1,#tabs3").hide();
        $("#tabs2").show();
      $("#two").addClass('text-blue-800 active');
      $("#one,#three").removeClass('text-blue-800 active').addClass('text-blue-400');
  
    }   
  }

  //Suppliers
  $('#new-catalog-supplier').click((event) => {
    event.preventDefault();
    $('#modal-catalog-supplier').toggle();
  });

  $('#addNewSupplierFiles').click(function () {
    var AUTH_TOKEN = $("meta[name=csrf-token]").attr("content");
    let formData = new FormData();
    let supplierId = $('#supplier_id').val();
    showLoading();
    formData.append('id',supplierId)
    $.each($("#supplier_upload_files"), function (i, obj) {
      $.each(obj.files, function (i, file) {
        formData.append('files[' + i + ']', file);
      });
    });
    $("#supplier_upload_files").val('');
    formData.append('authenticity_token', AUTH_TOKEN);
    $.ajax({
      url: '/suppliers/add_files',
      type: 'POST',
      data: formData,
      contentType: false,
      processData: false,
      success: function (data) {
        if (data['error'] == null) {
          Swal.fire(
            '¡Correcto!',
            'Los archivos se han añadido con éxito',
            'success'
          )
          let array_id_files = data['id_files']
          let files_information = data['files_information']
          $("#download-supplier-files").empty();
          array_id_files.forEach((element, index) => {
            $("#download-supplier-files").append('<li class="border-gray-200 flex flex-row p-1.5">' +
              '<div class="select-none bg-gray-200 rounded-md flex flex-1 items-center p-4  transition duration-500 ease-in-out transform hover:-translate-y-1 hover:shadow-lg">' +
                '<div class="delete-file flex flex-col rounded-md w-10 h-10 cursor-pointer justify-center items-center mr-4" onclick="deleteFile(this);">❌' +
                '<input class="supplier-id hidden" type="text" value="'+ supplierId +'">' +
                '<input class="file-url hidden" type="text" value="uploads/consur/files/suppliers/'+ supplierId + '/' + element +'/'+ files_information[element] +'">' +
                '<input class="type hidden" type="text" value="supplier">' +
                '</div>' +
                '<div class="flex-1 pl-1 mr-16">' +
                  '<div class="font-medium">'+ files_information[element] +'</div>' +
                '</div>' +
                '<a href="https://devso.s3.amazonaws.com/uploads/consur/files/suppliers/'+ supplierId +'/'+ element +'/'+ files_information[element] +'" download><svg class="w-8 h-8 cursor-pointer" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"></path></svg></a>' +
              '</div>' +
            '</li>');
          });
        } else {
          if (data['error'] == "error_file_max_size") {
            Swal.fire(
              'Verifique el tamaño del archivo',
              'Recuerde que solo puede subir hasta 10MB.',
              'warning'
            )
          }
          if (data['error'] == "error_no_files") {
            Swal.fire(
              'Seleccione uno o más archivos',
              'No se seleccionaron los archivos que desea subir',
              'warning'
            )
          }
        }
        $("#supplier-pre-load").empty();
        $("#supplier-pre-load").append('<li class="border-gray-400 flex flex-row p-1.5">' +
        '<div class="select-none cursor-pointer bg-gray-200 rounded-md flex flex-1 items-center p-4  transition duration-500 ease-in-out transform hover:-translate-y-1 hover:shadow-lg">' +
          '<div class="flex flex-col rounded-md w-10 h-10 bg-gray-300 justify-center items-center mr-4">📄</div>' +
          '<div class="flex-1 pl-1 mr-16">' +
            '<div class="font-medium">No hay archivos seleccionados</div>' +
          '</div>' +
          '<div class="text-gray-600 text-xs"></div>' +
        '</div>' +
      '</li>');
      }
    });
  });

  $('#supplier_upload_files').change(function () {
    $("#supplier-pre-load").empty();
    $.each($("#supplier_upload_files"), function (i, obj) {
      $.each(obj.files, function (i, file) {
        if (file.size > 20971520) {
          Swal.fire(
            'Verifique el tamaño de uno de los archivos',
            'Recuerde que solo puede subir hasta 20MB.',
            'warning'
          )
          $("#supplier_upload_files").val('')
          return
        }
        $("#supplier-pre-load").append('<li class="border-gray-400 flex flex-row mb-2">' +
        '<div class="select-none cursor-pointer bg-gray-200 rounded-md flex flex-1 items-center p-4  transition duration-500 ease-in-out transform hover:-translate-y-1 hover:shadow-lg">' +
          '<div class="flex flex-col rounded-md w-10 h-10 bg-gray-300 justify-center items-center mr-4">📄</div>' +
          '<div class="flex-1 pl-1 mr-16">' +
            '<div class="font-medium">'+ file.name +'</div>' +
          '</div>' +
          '<div class="text-gray-600 text-xs">'+ (file.size/1048576).toFixed(2) +' MB</div>' +
        '</div>' +
      '</li>');
      });
    });
    if ($("#supplier_upload_files").val() == '') {
      $("#supplier-pre-load").append('<li class="border-gray-400 flex flex-row mb-2">' +
        '<div class="select-none cursor-pointer bg-gray-200 rounded-md flex flex-1 items-center p-4  transition duration-500 ease-in-out transform hover:-translate-y-1 hover:shadow-lg">' +
          '<div class="flex flex-col rounded-md w-10 h-10 bg-gray-300 justify-center items-center mr-4">📄</div>' +
          '<div class="flex-1 pl-1 mr-16">' +
            '<div class="font-medium">No hay archivos seleccionados</div>' +
          '</div>' +
          '<div class="text-gray-600 text-xs"></div>' +
        '</div>' +
      '</li>');
    }
  });

  //Clients
  $('#new-catalog-client').click((event) => {
    event.preventDefault();
    $('#modal-catalog-client').toggle();
  });

  $('.close-modal').click(() => {
    $('.modal').hide();
  });

  //Clients
  $('#new-catalog-account').click((event) => {
    event.preventDefault();
    $('#modal-catalog-account').toggle();
  });

  $('.edit-catalog-account').click((event) => {
    $('#account_id').val('');
    $('#account_update_name').val('');
    $('#account_update_type').val('');
    $('#account_update_number').val('');

    var AUTH_TOKEN = $('meta[name=csrf-token]').attr('content');
    let account_id = $(event.target).closest('div').closest('.container-menu-accounts').find('.account-id').text();
    $.ajax({
      url: "/accounts/get_account",
      method: "GET",
      dataType: "json",
      data: {
        id: account_id,
        authenticity_token: AUTH_TOKEN
      },
      error: function (xhr, status, error) {
        console.error('AJAX Error: ' + status + error);
      },
      success: function (response) {
        $('#account_id').val(account_id);
        $('#account_update_name').val(response['name']);
        $('#account_update_type').val(response['type']);
        $('#account_update_number').val(response['number']);
      }
    });
    event.preventDefault();
    $('#modal-catalog-edit-account').toggle();
  });

  $('#existent-user').change(function(item) {
    let value = $('#existent-user option:selected').val();
    let information = value.split(':');
    let id_user = information[0];
    let role = information[1];

    $('#existent-role-user').val(role);
    $('.update-user').show();
  });

  $('.update-user').click(function() {
    let value = $('#existent-user option:selected').val();
    let information = value.split(':');
    let id_user = information[0];

    let value_role = $('#existent-role-user option:selected').val();
    var AUTH_TOKEN = $('meta[name=csrf-token]').attr('content');

    $.ajax({
      url: "/catalogs/update_role",
      method: "POST",
      dataType: "json",
      data: {
        authenticity_token: AUTH_TOKEN,
        catalog: {
          id: id_user,
          role: value_role
        }
      },
      error: function (xhr, status, error) {
        console.error('AJAX Error: ' + status + error);
      },
      success: function (response) {
        Swal.fire(
          'Guardado exitoso',
          'El usuario ha sido actualizado exitosamente.',
          'success'
        )
      }
    });
  });

  $('#add-new-user').submit(function(e) {
    e.preventDefault();
    Swal.fire({
      title: 'Enviando e-mail',
      text: 'Espere un momento, nos encontramos generando y enviando el correo a su colaborador...',
      onOpen: function () {
        Swal.showLoading()
      }
    });
    document.getElementById("add-new-user-button").value = "Cargando...";
  });

  showVisibilityAnimation = (modalName) => {
    modalName.css("visibility", "visible").animate({ opacity: 1 });
  };

  hideVisibilityAnimation = (modalName) => {
    let modal = $(modalName);

    modal.css("visibility", "hidden");
    modal.css("opacity", "0");
  };

  function showLoading() {
    let timerInterval
    Swal.fire({
      title: 'Subiendo los archivos',
      html: 'Espere un momento, los archivos se están subiendo al servidor',
      didOpen: () => {
        Swal.showLoading()
      },
    }).then((result) => {
      /* Read more about handling dismissals below */
      if (result.dismiss === Swal.DismissReason.timer) {
      }
    })
  };

  $('#addNewClientFiles').click(function () {
    var AUTH_TOKEN = $("meta[name=csrf-token]").attr("content");
    let formData = new FormData();
    let clientId = $('#client_id').val();
    showLoading();
    formData.append('id',clientId)
    $.each($("#client_upload_files"), function (i, obj) {
      $.each(obj.files, function (i, file) {
        formData.append('files[' + i + ']', file);
      });
    });
    $("#client_upload_files").val('');
    formData.append('authenticity_token', AUTH_TOKEN);
    $.ajax({
      url: '/clients/add_files',
      type: 'POST',
      data: formData,
      contentType: false,
      processData: false,
      success: function (data) {
        if (data['error'] == null) {
          Swal.fire(
            '¡Correcto!',
            'Los archivos se han añadido con éxito',
            'success'
          )
          let array_id_files = data['id_files']
          let files_information = data['files_information']
          $("#download-client-files").empty();
          array_id_files.forEach((element, index) => {
            $("#download-client-files").append('<li class="border-gray-200 flex flex-row p-1.5">' +
              '<div class="select-none bg-gray-200 rounded-md flex flex-1 items-center p-4  transition duration-500 ease-in-out transform hover:-translate-y-1 hover:shadow-lg">' +
                '<div class="delete-file flex flex-col rounded-md w-10 h-10 cursor-pointer justify-center items-center mr-4" onclick="deleteFile(this);">❌' +
                '<input class="client-id hidden" type="text" value="'+ clientId +'">' +
                '<input class="file-url hidden" type="text" value="uploads/consur/files/clients/'+ clientId + '/' + element +'/'+ files_information[element] +'">' +
                '<input class="type hidden" type="text" value="client">' +
                '</div>' +
                '<div class="flex-1 pl-1 mr-16">' +
                  '<div class="font-medium">'+ files_information[element] +'</div>' +
                '</div>' +
                '<a href="https://devso.s3.amazonaws.com/uploads/consur/files/clients/'+ clientId +'/'+ element +'/'+ files_information[element] +'" download><svg class="w-8 h-8 cursor-pointer" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"></path></svg></a>' +
              '</div>' +
            '</li>');
          });
        } else {
          if (data['error'] == "error_file_max_size") {
            Swal.fire(
              'Verifique el tamaño del archivo',
              'Recuerde que solo puede subir hasta 10MB.',
              'warning'
            )
          }
          if (data['error'] == "error_no_files") {
            Swal.fire(
              'Seleccione uno o más archivos',
              'No se seleccionaron los archivos que desea subir',
              'warning'
            )
          }
        }
        $("#client-pre-load").empty();
        $("#client-pre-load").append('<li class="border-gray-400 flex flex-row p-1.5">' +
        '<div class="select-none cursor-pointer bg-gray-200 rounded-md flex flex-1 items-center p-4  transition duration-500 ease-in-out transform hover:-translate-y-1 hover:shadow-lg">' +
          '<div class="flex flex-col rounded-md w-10 h-10 bg-gray-300 justify-center items-center mr-4">📄</div>' +
          '<div class="flex-1 pl-1 mr-16">' +
            '<div class="font-medium">No hay archivos seleccionados</div>' +
          '</div>' +
          '<div class="text-gray-600 text-xs"></div>' +
        '</div>' +
      '</li>');
      }
    });
  });

  $('#client_upload_files').change(function () {
    $("#client-pre-load").empty();
    $.each($("#client_upload_files"), function (i, obj) {
      $.each(obj.files, function (i, file) {
        if (file.size > 20971520) {
          Swal.fire(
            'Verifique el tamaño de uno de los archivos',
            'Recuerde que solo puede subir hasta 20MB.',
            'warning'
          )
          $("#client_upload_files").val('')
          return
        }
        $("#client-pre-load").append('<li class="border-gray-400 flex flex-row mb-2">' +
        '<div class="select-none cursor-pointer bg-gray-200 rounded-md flex flex-1 items-center p-4  transition duration-500 ease-in-out transform hover:-translate-y-1 hover:shadow-lg">' +
          '<div class="flex flex-col rounded-md w-10 h-10 bg-gray-300 justify-center items-center mr-4">📄</div>' +
          '<div class="flex-1 pl-1 mr-16">' +
            '<div class="font-medium">'+ file.name +'</div>' +
          '</div>' +
          '<div class="text-gray-600 text-xs">'+ (file.size/1048576).toFixed(2) +' MB</div>' +
        '</div>' +
      '</li>');
      });
    });
    if ($("#client_upload_files").val() == '') {
      $("#client-pre-load").append('<li class="border-gray-400 flex flex-row mb-2">' +
        '<div class="select-none cursor-pointer bg-gray-200 rounded-md flex flex-1 items-center p-4  transition duration-500 ease-in-out transform hover:-translate-y-1 hover:shadow-lg">' +
          '<div class="flex flex-col rounded-md w-10 h-10 bg-gray-300 justify-center items-center mr-4">📄</div>' +
          '<div class="flex-1 pl-1 mr-16">' +
            '<div class="font-medium">No hay archivos seleccionados</div>' +
          '</div>' +
          '<div class="text-gray-600 text-xs"></div>' +
        '</div>' +
      '</li>');
    }
  });

  function range(start, end) {
    return Array(end - start + 1).fill().map((_, idx) => start + idx);
  }

  $('.negative-option-account').change(function () {
    var AUTH_TOKEN = $('meta[name=csrf-token]').attr('content');
    let account_id = $(this).closest('div').closest('.container-menu-accounts').find('.account-id').text();
    let element = $(this)
    let accept_negative = false;
    if (this.checked) {
      accept_negative = true
    };

    $.ajax({
      url: "/accounts/change_status",
      method: "POST",
      dataType: "json",
      data: {
        id: account_id,
        accept_negative: accept_negative,
        authenticity_token: AUTH_TOKEN
      },
      error: function (xhr, status, error) {
        console.error('AJAX Error: ' + status + error);
      },
      success: function (response) {
        if (response['error']) {
          Swal.fire(
            'Alerta',
            'Existe un saldo de la cuenta, Unidad de Negocio o Proyecto inmobiliario en negativo.',
            'warning'
          );
          element[0].checked = true;
        }
      }
    });
  });

  if ($("#inmobiliary-projects-table").length) {
    $('#inmobiliary-projects-table').DataTable({
      "language": {
        "lengthMenu": "Mostrar _MENU_ registros por página",
        "zeroRecords": "Nada encontrado",
        "info": "Mostrando página _PAGE_ de _PAGES_",
        "infoEmpty": "No hay registros disponibles",
        "infoFiltered": "(filtrado de _MAX_ registros)",
        "sSearch": "Buscar:",
        "oPaginate": {
          "sFirst": "Primero",
          "sPrevious": "Anterior",
          "sNext": "Siguiente",
          "sLast": "Último"
        }
      },
      "lengthMenu": [[10, 25, 50, -1], [10, 25, 50, "All"]],
      dom: 'Blftip',
      buttons: [
        $.extend(true, {}, buttonCommon, {
          extend:'copyHtml5',
          title: "Proyectos inmobiliarios",
          messageTop: `Fecha de exportación: ${datetime}\n`,
          exportOptions: { 
            columns: [1, 2, 3, 4, 5, 6],
            rows: ':visible'
          },
        }),
        {
          extend:'excelHtml5',
          title: "Proyectos Inmobiliarios",
          messageTop: `Fecha de exportación: ${datetime}\n`,
          exportOptions: { 
            columns: [2, 3, 4 ,5],
            rows: ':visible'
          },
        },
        {
          extend: 'pdfHtml5',
          title: "Proyectos Inmobiliarios",
          messageTop: `Fecha de exportación: ${datetime}`,
          exportOptions:{
            columns: [2, 3, 4 ,5]
          },
          customize: function ( doc ) {
            if ( img64 != '' ) {
              doc.content.splice( 1, 0, {
                  margin: [ 0, 0, 0, 12 ],
                  alignment: 'left',
                  image: img64,
                  width: 170,
                  height: 30
              } );
            }
          }
        },
      ]
    });
  }

  if ($("#business-projects-table").length) {
    $('#business-projects-table').DataTable({
      "language": {
        "lengthMenu": "Mostrar _MENU_ registros por página",
        "zeroRecords": "Nada encontrado",
        "info": "Mostrando página _PAGE_ de _PAGES_",
        "infoEmpty": "No hay registros disponibles",
        "infoFiltered": "(filtrado de _MAX_ registros)",
        "sSearch": "Buscar:",
        "oPaginate": {
          "sFirst": "Primero",
          "sPrevious": "Anterior",
          "sNext": "Siguiente",
          "sLast": "Último"
        }
      },
      "lengthMenu": [[10, 25, 50, -1], [10, 25, 50, "All"]],
      dom: 'Blftip',
      "drawCallback": function(){
        columnsWithSum = [3, 4, 5];
        var api = this.api();

        $(api.column(1).footer()).html("Total: ");

        for (let i = 0; i < columnsWithSum.length; i++) {
          $(api.column(columnsWithSum[i]).footer()).html(
            sum(api.column(columnsWithSum[i], {page:'current'}).nodes()).toLocaleString('en-US', { maximumFractionDigits: 2,minimumFractionDigits: 2, style: 'currency', currency: 'USD' })
          );
        }
      },
      buttons: [
        $.extend(true, {}, buttonCommon, {
          extend:'copyHtml5',
          title: "Proyectos de Unidades de negocio",
          footer: true,
          messageTop: `Fecha de exportación: ${datetime}\n`,
          exportOptions: { 
            columns: [1, 2, 3, 4, 5, 6, 7, 8],
            rows: ':visible'
          },
        }),
        {
          extend:'excelHtml5',
          title: "Proyectos de Unidades de negocio",
          messageTop: `Fecha de exportación: ${datetime}\n`,
          footer: true,
          customize: function ( xlsx ) {
            var sheet = xlsx.xl.worksheets['sheet1.xml'];

            $('row:last c', sheet).attr('s','57');
          },
          exportOptions: { 
            columns: [1, 2, 3, 4 , 5, 6, 7, 8] 
          },
        },
        {
          extend: 'pdfHtml5',
          title: "Proyectos de Unidades de negocio",
          messageTop: `Fecha de exportación: ${datetime}`,
          footer: true,
          exportOptions:{
            columns: [1, 2, 3, 4, 5, 6, 7, 8],
            rows: ':visible'
          },
          customize: function ( doc ) {
            if ( img64 != '' ) {
              doc.content.splice( 1, 0, {
                  margin: [ 0, 0, 0, 12 ],
                  alignment: 'left',
                  image: img64,
                  width: 170,
                  height: 30
              } );
            }
          }
        }
      ],
    });
  }

  if ( $("#table-total-sum-account").length ) {
    $('#table-total-sum-account').DataTable({
      scrollY:        '68vh',
      scrollX:        true,
      scrollCollapse: true,
      "language": {
        "lengthMenu": "Mostrar _MENU_ registros por página",
        "zeroRecords": "Nada encontrado",
        "info": "Mostrando página _PAGE_ de _PAGES_",
        "infoEmpty": "No hay registros disponibles",
        "infoFiltered": "(filtrado de _MAX_ registros)",
        "sSearch": "Buscar:",
        "oPaginate": {
          "sFirst": "Primero",
          "sPrevious": "Anterior",
          "sNext": "Siguiente",
          "sLast": "Último"
        }
      },
      dom: 'Blftip',
      ordering: false,
      bFilter: false,
      bInfo: false,
      bPaginate: false,
      bLengthChange : false,
      buttons: [
        {
          extend:'copyHtml5',
          title: "Sumatoria total por tipo de cuenta",
          messageTop: `Fecha de exportación: ${datetime}\n`
        },
        {
          extend:'excelHtml5',
          title: "Sumatoria total por tipo de cuenta",
          messageTop: `Fecha de exportación: ${datetime}\n`
        },
        {
          extend: 'pdfHtml5',
          title: "Sumatoria total por tipo de cuenta",
          messageTop: `Fecha de exportación: ${datetime}`,
          customize: function ( doc ) {
            if ( img64 != '' ) {
              doc.content.splice( 1, 0, {
                margin: [ 0, 0, 0, 12 ],
                alignment: 'left',
                image: img64,
                width: 170,
                height: 30
              } );
            }
          }
        }
      ],
    });
  }

  if ( $("#table-accounts").length ) {
    $('#table-accounts').DataTable({
      scrollY:        '68vh',
      scrollX:        true,
      scrollCollapse: true,
      responsive: true,
      "language": {
        "lengthMenu": "Mostrar _MENU_ registros por página",
        "zeroRecords": "Nada encontrado",
        "info": "Mostrando página _PAGE_ de _PAGES_",
        "infoEmpty": "No hay registros disponibles",
        "infoFiltered": "(filtrado de _MAX_ registros)",
        "sSearch": "Buscar:",
        "oPaginate": {
          "sFirst": "Primero",
          "sPrevious": "Anterior",
          "sNext": "Siguiente",
          "sLast": "Último"
        }
      },
      dom: 'Blftip',
      ordering: false,
      bFilter: false,
      bInfo: false,
      bPaginate: false,
      bLengthChange : false,
      buttons: [
        {
          extend:'copyHtml5',
          title: "Cuentas",
          messageTop: `Fecha de exportación: ${datetime}\n`,
          exportOptions: { 
            columns: [1, 2] 
          },
          footer: true,
        },
        {
          extend:'excelHtml5',
          title: "Cuentas",
          messageTop: `Fecha de exportación: ${datetime}\n`,
          exportOptions: { 
            columns: [1, 2] 
          },
          footer: true,
          customize: function ( xlsx ) {
            var sheet = xlsx.xl.worksheets['sheet1.xml'];
            let last_row = $('row', sheet).length

            $('c[r^="B'+last_row+'"]', sheet).attr('s','57');
          },
        },
        {
          extend: 'pdfHtml5',
          title: "Cuentas",
          messageTop: `Fecha de exportación: ${datetime}`,
          exportOptions:{
            columns: [1, 2],
            rows: ':visible'
          },
          footer: true,
          customize: function ( doc ) {
            if ( img64 != '' ) {
              doc.content.splice( 1, 0, {
                margin: [ 0, 0, 0, 12 ],
                alignment: 'left',
                image: img64,
                width: 170,
                height: 30
              } );
            }
          }
        }
      ],
      "drawCallback":function(){
        var api = this.api()
        $(api.column(1).footer()).html(
          'Total: '
        )

        $(api.column(2).footer()).html(
          sum(api.column(2, {page:'current'}).data()).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' })
        )
      }
    });
  }

  function styleRowDatatable(doc, indexRow, rowfillColor) {
    hashStyles = {fontSize: 11, bold: true, fillColor: rowfillColor}

    for (let j = 0; j <  doc.content[2].table.body[indexRow].length; j++) {
      doc.content[2].table.body[indexRow][j].style = hashStyles;
    }
  }

  if($('#table-users').length) {
    $('#table-users').DataTable({
      scrollCollapse: true,
      initComplete: function() {
        $(this.api().table().container()).find('input').parent().wrap('<form>').parent().attr('autocomplete', 'off');
      },
      "language": {
        "lengthMenu": "Mostrar _MENU_ registros por página",
        "zeroRecords": "Nada encontrado",
        "info": "Mostrando página _PAGE_ de _PAGES_",
        "infoEmpty": "No hay registros disponibles",
        "infoFiltered": "(filtrado de _MAX_ registros)",
        "sSearch": "Buscar:",
        "oPaginate": {
          "sFirst": "Primero",
          "sPrevious": "Anterior",
          "sNext": "Siguiente",
          "sLast": "Último"
        }
      },
      dom: 'Blftip',
      buttons: [
        {
          extend:'copyHtml5',
          title: "Usuarios",
          messageTop: `Fecha de exportación: ${datetime}\n`,
          exportOptions: { 
            columns: [1, 2, 3] 
          },
        },
        {
          extend:'excelHtml5',
          title: "Usuarios",
          messageTop: `Fecha de exportación: ${datetime}\n`,
          exportOptions: { 
            columns: [1, 2, 3] 
          },
        },
        {
          extend: 'pdfHtml5',
          title: "Usuarios",
          messageTop: `Fecha de exportación: ${datetime}`,
          exportOptions:{
            columns: [1, 2, 3],
            rows: ':visible'
          },
          customize: function ( doc ) {
            if ( img64 != '' ) {
              doc.content.splice( 1, 0, {
                margin: [ 0, 0, 0, 12 ],
                alignment: 'left',
                image: img64,
                width: 170,
                height: 30
              } );
            }
          }
        }
      ],
    });
  }

  //Workers
  if($('#table-workers').length) {
    $('#table-workers').DataTable({
      scrollY:        '68vh',
      scrollX:        true,
      scrollCollapse: true,
      initComplete: function() {
        $(this.api().table().container()).find('input').parent().wrap('<form>').parent().attr('autocomplete', 'off');
      },
      "language": {
        "lengthMenu": "Mostrar _MENU_ registros por página",
        "zeroRecords": "Nada encontrado",
        "info": "Mostrando página _PAGE_ de _PAGES_",
        "infoEmpty": "No hay registros disponibles",
        "infoFiltered": "(filtrado de _MAX_ registros)",
        "sSearch": "Buscar:",
        "oPaginate": {
          "sFirst": "Primero",
          "sPrevious": "Anterior",
          "sNext": "Siguiente",
          "sLast": "Último"
        }
      },
      dom: 'Blftip',
      buttons: [
        {
          extend:'copyHtml5',
          title: "Colaboradores",
          messageTop: `Fecha de exportación: ${datetime}\n`,
          exportOptions: { 
            columns: [1, 2, 3, 4, 5] 
          },
        },
        {
          extend:'excelHtml5',
          title: "Colaboradores",
          messageTop: `Fecha de exportación: ${datetime}\n`,
          exportOptions: { 
            columns: [1, 2, 3, 4, 5] 
          },
        },
        {
          extend: 'pdfHtml5',
          title: "Colaboradores",
          messageTop: `Fecha de exportación: ${datetime}`,
          exportOptions:{
            columns: [1, 2, 3, 4, 5],
            rows: ':visible'
          },
          customize: function ( doc ) {
            if ( img64 != '' ) {
              doc.content.splice( 1, 0, {
                margin: [ 0, 0, 0, 12 ],
                alignment: 'left',
                image: img64,
                width: 170,
                height: 30
              } );
            }
          }
        }
      ],
    });
  }

  //Worker types
  if($('#table-worker-type').length) {
    $('#table-worker-type').DataTable({
      scrollY:        '68vh',
      scrollX:        true,
      scrollCollapse: true,
      "language": {
        "lengthMenu": "Mostrar _MENU_ registros por página",
        "zeroRecords": "Nada encontrado",
        "info": "Mostrando página _PAGE_ de _PAGES_",
        "infoEmpty": "No hay registros disponibles",
        "infoFiltered": "(filtrado de _MAX_ registros)",
        "sSearch": "Buscar:",
        "oPaginate": {
          "sFirst": "Primero",
          "sPrevious": "Anterior",
          "sNext": "Siguiente",
          "sLast": "Último"
        }
      },
      dom: 'Blftip',
      buttons: [
        {
          extend:'copyHtml5',
          title: "Puestos",
          messageTop: `Fecha de exportación: ${datetime}\n`,
          exportOptions: { 
            columns: [1, 2, 3] 
          },
        },
        {
          extend:'excelHtml5',
          title: "Puestos",
          messageTop: `Fecha de exportación: ${datetime}\n`,
          exportOptions: { 
            columns: [1, 2, 3] 
          },
        },
        {
          extend: 'pdfHtml5',
          title: "Puestos",
          messageTop: `Fecha de exportación: ${datetime}`,
          exportOptions:{
            columns: [1, 2, 3],
            rows: ':visible'
          },
          customize: function ( doc ) {
            if ( img64 != '' ) {
              doc.content.splice( 1, 0, {
                margin: [ 0, 0, 0, 12 ],
                alignment: 'left',
                image: img64,
                width: 170,
                height: 30
              } );
            }
          }
        }
      ],
    });
  }
  
  $('#new-worker').click((event) => {
    event.preventDefault();
    $('#modal-new-worker').toggle();
  });
  $('#new-worker-type').click((event) => {
    event.preventDefault();
    $('#modal-new-worker-type').toggle();
  });

  //Planning
  if ( $("#table-planning-subcategories").length ) {
    $('#table-planning-subcategories').DataTable({
      "paging": false,
      "info": false,
      "searching": false,
      "drawCallback":function(){
        index = 2;
        var numberOfColumns = $('#table-planning-subcategories thead th').length;
        var category = 'Pres';

        var api = this.api();
        $(api.column(1).footer()).html('Total: ');

        if (category == '') {
          
        } else {
          for (index; index < numberOfColumns-1; index++) {
            $(api.column(index).footer()).html('  ' +
              sum(api.column(index, {page:'current'}).data()).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2, style: 'currency', currency: 'USD' }) + '  '
            )
          }
        }
        
      }
    });
  }

})
