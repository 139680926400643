var $  = require( 'jquery' );
var selectize = require('selectize');

window.addEventListener('popstate', function (event) {
  Turbolinks.clearCache();
  Turbolinks.visit(window.location);
});

$(document).on('turbolinks:load', () => {
  $('form').on('keypress', e => {
    if (e.keyCode == 13) {
        return false;
    }
  });

  if($('.successful-notice').text().includes('exitosamente') ||
     $('.successful-notice').text().includes('exitosa')
  ) {
    Swal.fire(
      'Guardado exitoso',
      $('.successful-notice').text(),
      'success'
    )
  }

  if($('.successful-notice').text().includes('creado') ||
     $('.successful-notice').text().includes('creada') ||
     $('.successful-notice').text().includes('coinciden') ||
     $('.successful-notice').text().includes('incompletos') ||
     $('.successful-notice').text().includes('incompletas') ||
     $('.successful-notice').text().includes('posee')
  ) {
    Swal.fire(
      'Alerta',
      $('.successful-notice').text(),
      'warning'
    )
  }
  if($('.select-normal').length) {
    $('.select-normal').selectize();
  }

  if($('.select-find').length) {
    $('.select-find').selectize({
      create: true,
      sortField: {
        field: 'text',
        direction: 'asc'
      },
      dropdownParent: 'body'
    });
    //element.closest('.selectize-control').show();
  }
  
  if($('.select-multiple-add').length) {
    $('.select-multiple-add').selectize({
      plugins: ['drag_drop'],
      maxItems: 50,
      create: true,
      sortField: {
        field: 'text',
        direction: 'asc'
      },
      dropdownParent: 'body'
    });
  }

  ['home','settings','projects', 'records', 'capture-hours', 'hours-management', 'resources-management',  'hour-summary', 'accounts', 'business-unit', 'budget', 'bu-projects', 'subcontracts', 'hours_registy',
    'sale-proceeds', 'cost-sales', 'financial-costs', 'operating-expenses', 'taxes', 'other-incomes', 'other-expenses',
    'catalogs-users', 'catalogs-worker-type' ,'catalogs-worker', 'catalogs-clients', 'catalogs-accounts', 'catalogs-projects', 'catalogs-units',
    'ip-index', 'ip-total-collection', 'ip-sales-progress', 'ip-income-statement','ip-cash-flow', 'ip-planned-cash-flow',
    'ip-investor-report', 'ip-cash-accounts', 'bu-index', 'bu-income-statement', 'bu-cash-flow', 'work-hours-matrix', 'ip-cash-accounts-business-unit', 'report-accounts',
    'payment-report', 'payments-management', 'account-status', 'sales_per_unit', 'ip-total-commission', 'bu-real-income-statement', 'catalogs-suppliers', 'ip-absorcion-sales'
  ].forEach((view) => {
    selectOptionLayoutPrincipal(view);
  });

  function selectOptionLayoutPrincipal(view) {
    if($(`.${view}-view`).length) {
      $(`.${view}`).css('background', 'rgba(220,20,60,.9)');
      $(`.${view}`).css('color', 'white');
    }
  };

  let perfilButton = $('#perfil-button');
  let perfilMenu = $('#perfil-menu');

  $('document').click((event) => {
    var isClickInside = perfilButton.contains(event.target);

    if (!isClickInside) {
      hide(perfilMenu);
      return;
    }

    if (perfilMenu.is(':hidden')) {
      hide(perfilMenu);
      return;
    }
    show(perfilMenu);
  });

  perfilButton.click((event) => {
    if (perfilMenu.is(':visible')) {
      hide(perfilMenu);
      return;
    }
    show(perfilMenu);
  });

  var show = function (elem) {
    elem.show();
  };

  // Hide an element
  var hide = function (elem) {
    elem.hide();
  };

  var logOutLinks = document.querySelectorAll('a.log-out');

  logOutLinks.forEach(function(link) {
    link.addEventListener('click', function(event) {
      event.preventDefault();
      let code = localStorage.getItem('code_user')

      $.ajax({
        url: "/electron_logged_users/delete_electron",
        method: "GET",
        dataType: "json",
        data: {code},
        error: function (xhr, status, error) {
          console.error('AJAX Error: ' + status + error);
        },
        success: function (response) {

        }
      });

      localStorage.removeItem('code_user');

      window.location.href = link.href;
    });
  });
});

