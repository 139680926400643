require("@rails/ujs").start()
require("turbolinks").start()
import('turbolinks').then( t => t.start())
require("channels")
require("jquery")
require("jquery-ui")
require("jquery-ui-sortable-npm")
require("popper.js")

import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
window.Swal = Swal;

//require('jszip' );
//require('pdfmake' );
require( 'datatables.net-dt' );
//require('datatables.net-autofill-dt' )();
//require('datatables.net-buttons-dt' );
//require('datatables.net-buttons/js/buttons.colVis.js' )();
//require('datatables.net-buttons/js/buttons.html5.js' )();
//require('datatables.net-colreorder-dt' )();
//require('datatables.net-fixedheader-dt' )();
//require('datatables.net-keytable-dt' )();
//require('datatables.net-responsive-dt' );
//require('datatables.net-scroller-dt' )();
//require('datatables.net-select-dt' )();

require('selectize')
require("packs/lib/alpine.min.js")
require("packs/layout.js")
require("packs/projects.js")
require("packs/income_plannings.js")
require("packs/expense_plannings.js")
require("packs/cash_flows.js")
require("packs/sales.js")
require("packs/seeds.js")
require("packs/sales_charts.js")
require("packs/records.js")
require("packs/budgets.js")
require("packs/catalogs.js")
require("packs/business_units.js")
require("packs/settings.js")
require("packs/currencies.js")
require("packs/step2.js")
require("packs/step4.js")
require("packs/distributions.js")
require('packs/assignments.js')
require('packs/reports.js')
require('packs/home.js')

import * as Sortable from "sortablejs";
import Chart from 'chart.js';

import '../src/application.scss'

window.enterpriseLogo = '';

$.ajax({
    url: `/base_images/get_image`,
    method: "GET",
    dataType: "json",
    error: function(xhr, status, error) {
      console.error('AJAX Error: ' + status + error);
    },
    success: function(response) {
      window.enterpriseLogo = response['image'];
    }
  });

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
